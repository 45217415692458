import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  //{
  //  path: '/',
  //  component: Layout,
  //  redirect: '/index',
  //  name: 'Root',
  //  meta: {
  //    hidden: true
  //  }
  //},
  {
    path: '/',
    component: Layout,
    redirect: '/Home',
    name: 'Dashboard',
    meta: {},
    children: [
      {
        path: 'Home',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: t('router.dashboard'),
          noCache: true,
          icon: 'ant-design:dashboard-filled',
          affix: true
        }
      },
      {
        path: 'PersonalCenter',
        component: () => import('@/views/PersonalCenter/UserCard.vue'),
        name: 'PersonalCenter',
        meta: {
          title: t('common.PersonalCenter'),
          noCache: true,
          icon: 'ant-design:dashboard-filled',
          affix: false,
          hidden: true,
          noTagsView: false
        }
      },
      {
        path: 'AnnouncementDetails/:id',
        component: () => import('@/views/announcement/details/index.vue'),
        name: 'AnnouncementDetails',
        meta: {
          hidden: true,
          title: t('common.announcementDetails')
        }
      }
    ]
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'RedirectIndex',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/LoginT.vue'),
    name: 'LoginT',
    /***(to) => {
      //调整到单点登录
      if (import.meta.env.VITE_APP_AUTH_URL && import.meta.env.VITE_APP_AUTH_URL != '') {
        window.location.href = import.meta.env.VITE_APP_AUTH_URL
      } else {
        window.location.href = '/#/loginUser'
      }
      return
    },***/
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  //打印页面跳转
  // {
  //   path: '/material_print',
  //   component: () => import('@/views/wmsprint/print.vue'),
  //   name: 'materialPrint',
  //   meta: {
  //     title: '物料申请打印',
  //     hidden: true,
  //     noTagsView: true
  //   }
  // },
  {
    path: '/authLogin',
    component: () => import('@/views/Login/authRedirectNew.vue'),
    name: 'authRedirectNew',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/loginUser',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/test',
    component: () => import('@/views/evaluate/scoringTable/AbilityEvaluationTable.vue'),
    name: 'Test',
    meta: {
      hidden: true,
      title: '测试',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
  /*  ,{
    path: '/system',
    component: Layout,
    name: 'System',
    meta: {
      title: '系统管理'
    },
    children: [
      {
        path: 'admin',
        component: () => import('@/views/System/Admin/List.vue'),
        name: 'AdminList',
        meta: {
          title: '用户管理',
          noCache: true,
          icon: 'ant-design:dashboard-filled',
          affix: true
        }
      },
      {
        path: 'role',
        component: () => import('@/views/System/Role/List.vue'),
        name: 'RoleList',
        meta: {
          title: '角色管理',
          noCache: true,
          icon: 'ant-design:dashboard-filled',
          affix: true
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/System/Menu/List.vue'),
        name: 'MenuList',
        meta: {
          title: '菜单管理',
          noCache: true,
          icon: 'ant-design:dashboard-filled',
          affix: true
        }
      }
    ]
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = [
    'Redirect',
    'Login',
    'NoFind',
    'Root',
    'Dashboard',
    'PersonalCenter',
    'Analysis'
  ]
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
