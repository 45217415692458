<template>
  <el-upload
    class="upload-demo"
    drag
    action="/sit-api/aos/file/uploadFile"
    multiple
    ref="elupload"
    v-model:file-list="fileList"
    :before-upload="beforeUpload"
    :headers="headerObj"
    :on-success="onSuccess"
  >
    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    <div class="el-upload__text">{{ t('oppMag.uploadFile') }}</div>
    <template v-for="name in Object.keys($slots)" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </el-upload>
</template>

<script setup lang="ts">
import { reactive, ref, PropType } from 'vue'
import { UploadFilled } from '@element-plus/icons-vue'
import { useCache } from '@/hooks/web/useCache'
import { useI18n } from '@/hooks/web/useI18n'
import { ElMessage } from 'element-plus'
import type { UploadFile, UploadFiles, UploadRawFile, UploadStatus } from 'element-plus'
import { ElUpload } from 'element-plus'
import { Awaitable } from 'element-plus/es/utils/typescript'
const { t } = useI18n()
const { wsCache } = useCache()
const headerObj = reactive({
  'eb-token': wsCache.get('token')
})
defineProps({
  beforeUpload: Function as PropType<
    | ((rawFile: UploadRawFile) => Awaitable<boolean | void | File | Blob | null | undefined>)
    | undefined
  >
})
const elupload = ref<InstanceType<typeof ElUpload>>()
// const props = defineProps({
//   rowClassName: {
//     type: String,
//     default: null
//   }
// })
const fileList = ref<UploadFiles>([])
const emit = defineEmits(['beforeUpload'])
function onSuccess(response: any, _uploadFile: UploadFile, uploadFiles: UploadFiles) {
  if (response.code == '200') {
    emit('beforeUpload', response)
  } else {
    ElMessage.warning(response.message)
    uploadFiles.pop()
  }
}
defineExpose({
  fileList,
  abort: (file: UploadFile) => elupload.value?.abort(file),
  submit: () => elupload.value?.submit(),
  clearFiles: (states?: UploadStatus[] | undefined) => elupload.value?.clearFiles(states),
  handleStart: (rawFile: UploadRawFile) => elupload.value?.handleStart(rawFile),
  handleRemove: (file: UploadRawFile | UploadFile, rawFile?: UploadRawFile | undefined) =>
    elupload.value?.handleRemove(file, rawFile)
})
</script>

<style scoped></style>
