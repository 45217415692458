import { defineStore } from 'pinia'
import { store } from '@/store'
import { useCache } from '@/hooks/web/useCache'

const { wsCache } = useCache()

export interface EnumState {
  isSetEnum: boolean
  dictObj: Recordable
}

export const useEnumStore = defineStore('dict', {
  state: (): EnumState => ({
    isSetEnum: false,
    dictObj: {}
  }),
  getters: {
    getEnumObj(): Recordable {
      return this.dictObj
    },
    getIsSetEnum(): boolean {
      return this.isSetEnum
    }
  },
  actions: {
    setEnumObj(dictObj: Recordable) {
      this.dictObj = dictObj
      wsCache.set('dict', this.dictObj)
    },
    onlySetEnumObj(dictObj: Recordable) {
      this.dictObj = dictObj
    },
    setIsSetEnum(isSetEnum: boolean) {
      this.isSetEnum = isSetEnum
    }
  }
})

export const useEnumStoreWithOut = () => {
  return useEnumStore(store)
}

/**
 * 获取枚举名称/词汇名称
 * @param className 枚举类/词汇编码
 * @param val 枚举值/词汇值
 */
export const getEnumDesc = (className, val) => {
  const obj = wsCache.get('dict')
  const element = obj[className]
  if (!element) {
    return ''
  }
  for (let i = 0; i < element.length; i++) {
    if (element[i].code == val) {
      /***
      if (element[i].msgCode && element[i].msgCode != '') {
        const sp = 'enums.' + element[i].msgCode
        return t(sp)
      }***/
      return element[i].desc
    }
  }
  return ''
}

/**
 * 获取枚举数据/词汇数据
 * @param className 枚举类/词汇编码
 */
export const getEnumData = (
  className: string
): Array<{ code: string; desc: string; name: string; nameEn: string; externalCode: string }> => {
  const obj = wsCache.get('dict')
  const element = obj[className]
  if (!element) {
    return []
  }
  /***
  for (let i = 0; i < element.length; i++) {
    if (element[i].msgCode && element[i].msgCode != '') {
      const sp = 'enums.' + element[i].msgCode
      element[i].desc = t(sp)
    }
  }***/
  return element
}
